import axios from 'axios';
import emailjs from 'emailjs-com';



const host = 'https://api.victory-partners.ma';
const optionAxios = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export class Rest{


    register(data: any) {
        return axios.post(host + '/victory%20api/register.php',data,optionAxios);
    }
    sendEmail(data: any){
        return axios.get(host + '/victory%20api/email.php?email='+data.email+'&link='+data.link+'&name='+data.name,optionAxios);
    }
    clientIsValid(id: any){
        return axios.get(host + '/victory%20api/isvalid.php?id=' + id,optionAxios);
    }
    ClientIsExist(id: any){
        return axios.get(host + '/victory%20api/isexist.php?id=' + id,optionAxios);
    }
    ClientRegisterTimes(nom: any,date: any,email: any){
        return axios.get(host + '/victory%20api/haveregister.php?nom=' + nom + '&date=' + date + '&email=' + email,optionAxios);
    }
    getClient(id: any){
        return axios.get(host + '/victory%20api/load.php?type=client&id=' + id,optionAxios);
    }
    getTestSettings(idNiveau: any){
        return axios.get(host + '/victory%20api/load.php?type=settings&niveau=' + idNiveau,optionAxios);
    }
    getNiveaux(niveau: any){
        return axios.get(host + '/victory%20api/load.php?type=niveau&niveau=' + niveau,optionAxios);
    }
    getQuestions(IdMatiere: any, IdNiveau: any, qcm: any, comp: any, audio: any){
        return axios.get(host + '/victory%20api/getquestions.php?matiere=' + IdMatiere + '&niveau=' + IdNiveau+ '&qcm=' + qcm+ '&comp=' + comp+ '&audio=' + audio,optionAxios);
    }
    login(login: any,password: any){
        return axios.get(host + '/victory%20api/loginadmin.php?login=' + login + '&password=' + password,optionAxios);
    }
    getAdmin(){
        return axios.get(host + '/victory%20api/admin.php',optionAxios);
    }
    getUser(login: any){
        return axios.get(host + '/victory%20api/user.php?login='+login,optionAxios);
    }
    updateAdmin(data: any) {
        return axios.put(host + '/victory%20api/admin.php',data,optionAxios);
    }
    addAdmin(data: any) {
        return axios.post(host + '/victory%20api/admin.php',data,optionAxios);
    }
    deleteAdmin(data: any) {
        return axios.delete(host + '/victory%20api/admin.php?type=' + data.type + '&id=' + data.id,optionAxios);
    }
    getImages(){
        return axios.get(host + '/victory%20api/upload/images.php',optionAxios);
    }
    getAllNiveaux(){
        return axios.get(host + '/victory%20api/getniveau.php',optionAxios);
    }
    startTest(data: any){
        return axios.post(host + '/victory%20api/starttest.php',data,optionAxios);
    }
    endTest(data: any){
        return axios.put(host + '/victory%20api/endtest.php',data,optionAxios);
    }
    alreadyTested(id: any,lang: any){
        return axios.get(host + '/victory%20api/havetested.php?id=' + id + '&lang=' + lang,optionAxios);
    }
    getTest(id: any){
        return axios.get(host + '/victory%20api/gettest.php?id=' + id,optionAxios);
    }
    getAnnonce(){
        return axios.get(host + '/victory%20api/getannonce.php',optionAxios);
    }
    getDet(id: any){
        return axios.get(host + '/victory%20api/getdet.php?id=' + id,optionAxios);
    }
    getAllDet(id: any){
        return axios.get(host + '/victory%20api/getalldet.php?id=' + id,optionAxios);
    }
    getAnn(){
        return axios.get(host + '/victory%20api/getann.php',optionAxios);
    }
    confirmerQuestion(type: any,id: any){
        return axios.post(host + `/victory%20api/confirmerquestion.php?type=${type}&id=${id}`,optionAxios);
    }
    getMatieres(){
        return axios.get(host + '/victory%20api/matiere.php',optionAxios);
    }
    getVilles(){
        return axios.get(host + '/victory%20api/villes.php',optionAxios);
    }
}

