
window.onbeforeunload = null;
import router from "@/router";
import { Options, Vue } from "vue-class-component";
import { Rest } from "../providers/rest";

@Options({
  name: "Home",
  data: () => {
    return {
      nom: null,
      vNom: false,
      date: null,
      vDate: false,
      email: null,
      vEmail: false,
      vTel: false,
      tel: "",
      niveau: "",
      vill: null,
      fr: false,
      en: false,
      vLang: false,
      vVille:false,
      load: false,
      ville: "",
      autre: false,
      annonce: [],
      matieres: [],
      villes: [],
      alert: false,
      showForm:false,
    };
  },
  components: {},
  beforeMount() {
    new Rest().getAnnonce().then((r) => {
      if (r.data.success) {
        this.annonce = r.data.data;
      }
    });
    new Rest().getMatieres().then((rr) => {
      if (rr.data.success) {
        console.log(rr.data);
        this.matieres = rr.data.data;
      }
    });
    new Rest().getVilles().then((rrr) => {
      if (rrr.data.success) {
        console.log(rrr.data);
        this.villes = rrr.data.data;
      }
    });
  },
  methods: {
    fromShow(){
      this.showForm = true;
    },
    fromHide(){
      this.showForm = false;
    },
    show: function () {
      this.alert = true;
    },
    hide: function () {
      this.alert = false;
    },
    envoye: function () {
      this.load = true;
      let verify = true;
      if (!this.nom) {
        verify = false;
        this.vNom = true;
        const a = document.getElementById("name");
        if (a) {
          a.style.borderLeft = "5px solid red";
          (a.children as HTMLCollectionOf<HTMLElement>)[1].style.border =
            "1px solid red";
        }
      }
      if (!this.date) {
        verify = false;
        this.vDate = true;
        const a = document.getElementById("date");
        if (a) {
          a.style.borderLeft = "5px solid red";
          (a.children as HTMLCollectionOf<HTMLElement>)[1].style.border =
            "1px solid red";
        }
      }
      let Vlang = true;
      for(const lang of document.getElementsByName("lang")){
        if((lang as HTMLInputElement).checked == true){
          Vlang = false;
        }
      }
      if (Vlang) {
        verify = false;
        this.vLang = true;
        const a = document.getElementById("lang");
        if (a) {
          a.style.borderLeft = "5px solid red";
          (a.children as HTMLCollectionOf<HTMLElement>)[1].style.border =
            "1px solid red";
        }
      }
      if (!this.tel) {
        verify = false;
        this.vTel = true;
        const a = document.getElementById("tel");
        if (a) {
          a.style.borderLeft = "5px solid red";
          (a.children as HTMLCollectionOf<HTMLElement>)[1].style.border =
            "1px solid red";
        }
      }
      if (this.vill == null) {
        verify = false;
        this.vVille = true;
        const a = document.getElementById("ville");
        if (a) {
          a.style.borderLeft = "5px solid red";
          ((a.children as HTMLCollectionOf<HTMLElement>)[1].children as HTMLCollectionOf<HTMLElement>)[0].style.border =
            "1px solid red";
        }
      }
      if (isNaN(this.tel) || (this.tel.length != 9 && this.tel.length != 10)) {
        verify = false;
        this.vTel = true;
        const a = document.getElementById("tel");
        if (a) {
          a.style.borderLeft = "5px solid red";
          (a.children as HTMLCollectionOf<HTMLElement>)[1].style.border =
            "1px solid red";
        }
      }
      if (!this.email) {
        verify = false;
        this.vEmail = true;
        const a = document.getElementById("email");
        if (a) {
          a.style.borderLeft = "5px solid red";
          (a.children as HTMLCollectionOf<HTMLElement>)[1].style.border =
            "1px solid red";
        }
      } else {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(this.email.toLowerCase())) {
          verify = false;
          this.vEmail = true;
          const a = document.getElementById("email");
          if (a) a.style.borderLeft = "5px solid #fafa4fa3";
        }
      }
      if (verify) {
        const rest = new Rest();
        rest.ClientRegisterTimes(this.nom, this.date, this.email).then((cr) => {
          if (cr.data.success) {
            if (cr.data.isvalid >= 3) {
              this.show();
              setTimeout(() => {
                this.load = false;
              }, 500);
            } else {
              const data = {
                name: this.nom,
                email: this.email,
                date: this.date,
                tel: this.tel,
                niveau: "",
                fr: false,
                en: false,
                local: "0",
                ville: this.vill == "autre" ? this.ville : this.vill,
              };
              rest
                .register(data)
                .then((r) => {
                  if (r.data.success) {
                    let link = "";
                    const lang = document.getElementsByName("lang");
                    for (const l of lang) {
                      if ((l as HTMLInputElement).checked == true) {
                        const lang = (l as HTMLInputElement).value.split(
                          "/"
                        )[0];
                        const val = (l as HTMLInputElement).value.split("/")[1];
                        link +=
                          "<br /><span style='display:flex;align-items:center'>" +
                          lang +
                          ": https://test.victory-partners.ma/main/" +
                          val +
                          "/" +
                          r.data.id +
                          "</span>";
                      }
                    }

                    const emailData = {
                      name: this.nom,
                      email: this.email,
                      link: link,
                    };
                    rest.sendEmail(emailData).then(
                      (r) => {
                        setTimeout(() => {
                          this.load = false;
                        }, 500);
                        if (r.data.success)
                          router.push({
                            name: "GoToEmail",
                            params: { email: this.email },
                          });
                        else {
                          alert(r.data.msg);
                        }
                      },
                      () => {
                        setTimeout(() => {
                          this.load = false;
                        }, 500);
                        router.push({
                          name: "GoToEmail",
                          params: { email: this.email },
                        });
                      }
                    );
                  } else {
                    this.snackBar("erreur 404!");
                    setTimeout(() => {
                      this.load = false;
                    }, 500);
                  }
                })
                .catch((e) => {
                  this.snackBar("erreur 404! Veuillez réessayer!!");
                  setTimeout(() => {
                    this.load = false;
                  }, 500);
                });
            }
          } else {
            alert(cr.data.msg);
          }
        });
      } else {
        setTimeout(() => {
          this.load = false;
        }, 500);
      }
    },
    reColor: function (id: any) {
      const a = document.getElementById(id);
      if (a) {
        a.style.borderLeft = "none";
        (a.children as HTMLCollectionOf<HTMLElement>)[1].style.border = "none";
        ((a.children as HTMLCollectionOf<HTMLElement>)[1].children as HTMLCollectionOf<HTMLElement>)[0].style.border = "none";
      }
      if (id == "name") this.vNom = false;

      if (id == "email") this.vEmail = false;

      if (id == "date") this.vDate = false;

      if (id == "lang") this.vLang = false;

      if (id == "tel") this.vTel = false;

      if (id == "ville") this.vVille = false;

    },
    snackBar: function (msg: any) {
      const x = document.getElementById("snackbar");
      if (x) {
        x.className = "show";
        x.innerText = msg;
        setTimeout(function () {
          if (x) {
            x.className = x.className.replace("show", "");
          }
        }, 3000);
      }
    },
  },
})
export default class Home extends Vue {}
