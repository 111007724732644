<template>
  <div id="nav">
    <span id="logo">
      <img
        src="https://api.victory-partners.ma/victory%20api/upload/images/icons/logo.png"
        style="margin: 2px 5px"
        height="70"
        alt=""
      />
      <span>
        VICTORY Partners
        <div style="font-size: 16px; color: red; text-align: center">
          Centre de langues
        </div>
      </span>
    </span>
    <ul>
      <router-link to="/" exact><li>Accueil</li></router-link>
      <a href="https://victory-partners.ma/" target="_blank"
        ><li>Visiter notre site</li></a
      >
    </ul>
  </div>
  <router-view />
</template>

<style>
* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
a {
  text-decoration: none;
}

#nav {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #d2d2d2;
  border-bottom: 1px solid #e9e9eb;
}
#logo {
  width: 65%;
  padding-left: 5%;
  font-weight: bold;
  font-size: 30px;
  display: flex;
  align-items: center;
  font-family: sans-serif;
}
#nav ul {
  width: 35%;
  margin: auto;
}
#nav ul a {
  display: inline-block;
}
#nav ul li {
  color: black;
  display: inline-block;
  padding: 20px 15px;
  float: right;
}
#nav ul li:hover {
  color: red;
}
.router-link-exact-active li {
  text-decoration: underline;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  #nav {
    text-align: center;
    display: block;
  }
  #nav ul li {
    float: none;
  }
  #nav ul {
    padding-right: 0;
    width: 100%;
  }
  #logo {
    padding: 0;
    font-size: 20px;
  }
  #logo img,
  #logo span {
    margin: auto;
  }
}
@media screen and (max-width: 1200px) {
  #logo {
    padding: 0;
    font-size: 20px;
  }
}
</style>
