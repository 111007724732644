import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/help',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/main/:lang/:id/:start',
    name: 'Mainn',
    component: ()=> import('../views/Main.vue')
  },
  {
    path: '/main/:lang/:id/',
    name: 'Main',
    component: ()=> import('../views/Main.vue')
  },
  {
    path : '/gotoemail',
    name : 'GoToEmail',
    component: ()=> import('../views/Email.vue')
  },
  {
    path : '/notFound',
    name : 'NotFound',
    component: ()=> import('../views/Notfound.vue')
  },
  {
    path : '/tests',
    name : 'Test',
    component: ()=> import('../views/Test.vue')
  },
  {
    path : '/result',
    name : 'Result',
    component: ()=> import('../views/Result.vue')
  },
  {
    path : '/admin',
    name : 'Admin',
    component: ()=> import('../views/LoginAdmin.vue')
  },
  {
    path : '/adminPanel',
    name : 'AdminPanel',
    component: ()=> import('../views/AdminPanel.vue')
  },
  {
    path : '/:any',
    name : 'NotFound',
    component: ()=> import('../views/Notfound.vue')
  },
  {
    path : '/about',
    name : 'about',
    component: ()=> import('../views/About.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
