<template>
  <div class="home">
    <div v-if="alert" class="alert">
      <div class="alr">
        <div class="x" v-on:click="hide">X</div>
        <div class="child alrTitle">
          Vous avez atteint le nombre maximum de tests <br /><br />
          pour repasser un autre test de niveau veuillez contactez nous sur :
          <br />
          +212 5 22 03 04 99 <br />
          +212 6 17 76 41 52
        </div>
      </div>
    </div>
    <div
      :style="`
        background-image: url( ${require('@/assets/home.jpg')} );
        background-repeat: no-repeat;
        background-size: cover;
        height:500px;
        position: relative;
      `"
    >
      <div
        style="
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #0c0c0c5e;
        "
      >
        <h1 id="title">Passez votre test de niveau en ligne Gratuit</h1>
        <div style="text-align: center;">
          <button
          @click="fromShow()"
              style="
                padding: 15px 20px;
                border-radius: 25px;
                border: none;
                background-color: #00ce00;
                color: white;
                font-weight: bold;
                font-size: 20px;
                cursor: pointer;
                margin: 5px;
                display: inline-flex;
                align-items: center;
              "
            >
              <i style="font-size:30px;margin-right:5px" class="fa fa-hand-o-right" aria-hidden="true"></i> Commencer le test <i style="font-size:30px;margin-left:5px" class="fa fa-hand-o-left" aria-hidden="true"></i>
            </button>
        </div>
        <div style="text-align: center; margin-top: 10px">
          <a href="#footer"
            ><button
              style="
                padding: 10px 10px;
                border-radius: 25px;
                border: none;
                background-color: red;
                color: white;
                font-weight: bold;
                cursor: pointer;
                margin: 5px;
              "
            >
              Voir comment passer le test
            </button></a
          >
          <a href="#who"
            ><button
              style="
                padding: 10px 10px;
                border-radius: 25px;
                border: none;
                background-color: red;
                color: white;
                font-weight: bold;
                cursor: pointer;
              "
            >
              Voir Qui sommes-nous
            </button></a
          >
        </div>
        
      </div>
    </div>

    
  <h2
      class="eur"
      style="
        display: flex;
        align-items: center;
        width: 50%;
        margin: auto;
        margin-top: 20px;
        color: blue;
        font-size: 20px;
      "
    >
      <img width="70" src="@/assets/eur.jpg" alt="" />
      <span style="text-align: center"
        >Tous nos programmes sont conformes au CECRL (Le Cadre européen commun
        de réference pour les langues)</span
      >
      <img width="70" src="@/assets/eur.jpg" alt="" />
    </h2>
    <div style="text-align:center"></div>
    <div class="homecnt">
      
      <div v-if="load" class="load">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div style="text-align: center;" v-for="ann in annonce" :key="ann.id">
        <div v-if="ann.niveau == 'home image'" id="photo">
          <img id="laptop" width="700" src="../assets/laptop.jpg" alt="" />
        </div>
      </div>
      <div v-if="showForm" class="formCnt">
        <div id="form">
          <div class="homeX" @click="fromHide()">X</div>
          <div id="snackbar"></div>
          <h2 style="text-align: center; margin-bottom: 20px">
            Remplir le formulaire suivant pour passer le test:
          </h2>
          <div class="input" id="name">
            <span>Nom et prénom<label>*</label> :</span
            ><input v-on:click="reColor('name')" v-model="nom" type="text" />
          </div>
          <div v-if="vNom" class="error">Nom et prénom Obligatoire!</div>
          <div class="input" id="date">
            <span>Date de naissance<label>*</label> :</span
            ><input
              v-on:click="reColor('date')"
              v-model="date"
              :max="
                new Date(new Date().setYear(new Date().getFullYear() - 5))
                  .toISOString()
                  .split('T')[0]
              "
              type="date"
            />
          </div>
          <div v-if="vDate" class="error">Date de naissance Obligatoire!</div>
          <div class="input" id="email">
            <span>Email<label>*</label> :</span
            ><input
              placeholder="Entrer email valid!"
              v-on:click="reColor('email')"
              v-model="email"
              type="email"
            />
          </div>
          <div v-if="vEmail" class="error">Email invalide!</div>
          <div class="input" id="ville">
            <span>Ville actuelle<label>*</label> :</span>
            <span>
              <select name="" id="" v-model="vill" v-on:click="reColor('ville')">
                <option :value="null" disabled>
                  choisir ville
                </option>
                <option v-for="v of villes" :key="v.id" :value="v.nom">{{v.nom}}</option>
              </select>
            </span>
          </div>
          <div v-if="vVille" class="error">
            Veuillez choisir ville!
          </div>
          <div class="input" v-if="vill=='autre'">
            <span>Entrer votre ville :</span
            ><input v-model="ville" type="text" id="ville" />
          </div>
          <div class="input" id="tel">
            <span>Téléphone <label>*</label> :</span
            ><input
              v-on:click="reColor('tel')"
              v-model="tel"
              type="text"
              id="phone"
              placeholder="ex: 06XXXXXXXX"
            />
          </div>
          <div v-if="vTel" class="error">Téléphone format incorrect!</div>
          <div class="input" id="lang">
            <span>Intéréser par<label>*</label> :</span>
            <span id="intrs" style="display: inline-block">
              <span
                style="display: inline-block"
                v-for="m of matieres"
                :key="m.idMatiere"
              >
                {{ m.LibelleMatiere }}
                <span
                  v-if="m.disponible == 0"
                  style="display: inline-block; font-size: 12px; color: red"
                  >(pas disponible)</span
                ><input
                  v-on:click="reColor('lang')"
                  name="lang"
                  :disabled="m.disponible == 0 ? true : false"
                  type="checkbox"
                  :value="m.LibelleMatiere + '/' + m.idMatiere"
                />
              </span>
            </span>
          </div>
          <div v-if="vLang" class="error">
            Veuillez choisir au moins un choix!
          </div>
          <div class="btn"><button v-on:click="envoye">Envoyé</button></div>
        </div>
      </div>
    </div>
    <footer id="footer">
      <div class="tt">Comment passer le test?</div>
      <div class="how">
        <div
          v-for="ann in annonce"
          :key="ann.id"
          :style="
            ann.niveau == 'video'
              ? 'position: relative;width: 100%;height: 0;padding-bottom: 56.25%;margin:auto'
              : ''
          "
        >
          <iframe
            v-if="ann.niveau == 'video'"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            :src="ann.image"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <hr style="margin: 5px" />
      <div id="who" class="tt">Qui sommes-nous?</div>
      <div class="how">
        <div
          v-for="ann in annonce"
          :key="ann.id"
          :style="
            ann.niveau == 'Pvideo'
              ? 'position: relative;width: 100%;height: 0;padding-bottom: 56.25%;margin:auto'
              : ''
          "
        >
          <iframe
            v-if="ann.niveau == 'Pvideo'"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            :src="ann.image"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="footer">Copyright 2021 - Victory partners</div>
    </footer>
  </div>
</template>

<script lang="ts">
window.onbeforeunload = null;
import router from "@/router";
import { Options, Vue } from "vue-class-component";
import { Rest } from "../providers/rest";

@Options({
  name: "Home",
  data: () => {
    return {
      nom: null,
      vNom: false,
      date: null,
      vDate: false,
      email: null,
      vEmail: false,
      vTel: false,
      tel: "",
      niveau: "",
      vill: null,
      fr: false,
      en: false,
      vLang: false,
      vVille:false,
      load: false,
      ville: "",
      autre: false,
      annonce: [],
      matieres: [],
      villes: [],
      alert: false,
      showForm:false,
    };
  },
  components: {},
  beforeMount() {
    new Rest().getAnnonce().then((r) => {
      if (r.data.success) {
        this.annonce = r.data.data;
      }
    });
    new Rest().getMatieres().then((rr) => {
      if (rr.data.success) {
        console.log(rr.data);
        this.matieres = rr.data.data;
      }
    });
    new Rest().getVilles().then((rrr) => {
      if (rrr.data.success) {
        console.log(rrr.data);
        this.villes = rrr.data.data;
      }
    });
  },
  methods: {
    fromShow(){
      this.showForm = true;
    },
    fromHide(){
      this.showForm = false;
    },
    show: function () {
      this.alert = true;
    },
    hide: function () {
      this.alert = false;
    },
    envoye: function () {
      this.load = true;
      let verify = true;
      if (!this.nom) {
        verify = false;
        this.vNom = true;
        const a = document.getElementById("name");
        if (a) {
          a.style.borderLeft = "5px solid red";
          (a.children as HTMLCollectionOf<HTMLElement>)[1].style.border =
            "1px solid red";
        }
      }
      if (!this.date) {
        verify = false;
        this.vDate = true;
        const a = document.getElementById("date");
        if (a) {
          a.style.borderLeft = "5px solid red";
          (a.children as HTMLCollectionOf<HTMLElement>)[1].style.border =
            "1px solid red";
        }
      }
      let Vlang = true;
      for(const lang of document.getElementsByName("lang")){
        if((lang as HTMLInputElement).checked == true){
          Vlang = false;
        }
      }
      if (Vlang) {
        verify = false;
        this.vLang = true;
        const a = document.getElementById("lang");
        if (a) {
          a.style.borderLeft = "5px solid red";
          (a.children as HTMLCollectionOf<HTMLElement>)[1].style.border =
            "1px solid red";
        }
      }
      if (!this.tel) {
        verify = false;
        this.vTel = true;
        const a = document.getElementById("tel");
        if (a) {
          a.style.borderLeft = "5px solid red";
          (a.children as HTMLCollectionOf<HTMLElement>)[1].style.border =
            "1px solid red";
        }
      }
      if (this.vill == null) {
        verify = false;
        this.vVille = true;
        const a = document.getElementById("ville");
        if (a) {
          a.style.borderLeft = "5px solid red";
          ((a.children as HTMLCollectionOf<HTMLElement>)[1].children as HTMLCollectionOf<HTMLElement>)[0].style.border =
            "1px solid red";
        }
      }
      if (isNaN(this.tel) || (this.tel.length != 9 && this.tel.length != 10)) {
        verify = false;
        this.vTel = true;
        const a = document.getElementById("tel");
        if (a) {
          a.style.borderLeft = "5px solid red";
          (a.children as HTMLCollectionOf<HTMLElement>)[1].style.border =
            "1px solid red";
        }
      }
      if (!this.email) {
        verify = false;
        this.vEmail = true;
        const a = document.getElementById("email");
        if (a) {
          a.style.borderLeft = "5px solid red";
          (a.children as HTMLCollectionOf<HTMLElement>)[1].style.border =
            "1px solid red";
        }
      } else {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(this.email.toLowerCase())) {
          verify = false;
          this.vEmail = true;
          const a = document.getElementById("email");
          if (a) a.style.borderLeft = "5px solid #fafa4fa3";
        }
      }
      if (verify) {
        const rest = new Rest();
        rest.ClientRegisterTimes(this.nom, this.date, this.email).then((cr) => {
          if (cr.data.success) {
            if (cr.data.isvalid >= 3) {
              this.show();
              setTimeout(() => {
                this.load = false;
              }, 500);
            } else {
              const data = {
                name: this.nom,
                email: this.email,
                date: this.date,
                tel: this.tel,
                niveau: "",
                fr: false,
                en: false,
                local: "0",
                ville: this.vill == "autre" ? this.ville : this.vill,
              };
              rest
                .register(data)
                .then((r) => {
                  if (r.data.success) {
                    let link = "";
                    const lang = document.getElementsByName("lang");
                    for (const l of lang) {
                      if ((l as HTMLInputElement).checked == true) {
                        const lang = (l as HTMLInputElement).value.split(
                          "/"
                        )[0];
                        const val = (l as HTMLInputElement).value.split("/")[1];
                        link +=
                          "<br /><span style='display:flex;align-items:center'>" +
                          lang +
                          ": https://test.victory-partners.ma/main/" +
                          val +
                          "/" +
                          r.data.id +
                          "</span>";
                      }
                    }

                    const emailData = {
                      name: this.nom,
                      email: this.email,
                      link: link,
                    };
                    rest.sendEmail(emailData).then(
                      (r) => {
                        setTimeout(() => {
                          this.load = false;
                        }, 500);
                        if (r.data.success)
                          router.push({
                            name: "GoToEmail",
                            params: { email: this.email },
                          });
                        else {
                          alert(r.data.msg);
                        }
                      },
                      () => {
                        setTimeout(() => {
                          this.load = false;
                        }, 500);
                        router.push({
                          name: "GoToEmail",
                          params: { email: this.email },
                        });
                      }
                    );
                  } else {
                    this.snackBar("erreur 404!");
                    setTimeout(() => {
                      this.load = false;
                    }, 500);
                  }
                })
                .catch((e) => {
                  this.snackBar("erreur 404! Veuillez réessayer!!");
                  setTimeout(() => {
                    this.load = false;
                  }, 500);
                });
            }
          } else {
            alert(cr.data.msg);
          }
        });
      } else {
        setTimeout(() => {
          this.load = false;
        }, 500);
      }
    },
    reColor: function (id: any) {
      const a = document.getElementById(id);
      if (a) {
        a.style.borderLeft = "none";
        (a.children as HTMLCollectionOf<HTMLElement>)[1].style.border = "none";
        ((a.children as HTMLCollectionOf<HTMLElement>)[1].children as HTMLCollectionOf<HTMLElement>)[0].style.border = "none";
      }
      if (id == "name") this.vNom = false;

      if (id == "email") this.vEmail = false;

      if (id == "date") this.vDate = false;

      if (id == "lang") this.vLang = false;

      if (id == "tel") this.vTel = false;

      if (id == "ville") this.vVille = false;

    },
    snackBar: function (msg: any) {
      const x = document.getElementById("snackbar");
      if (x) {
        x.className = "show";
        x.innerText = msg;
        setTimeout(function () {
          if (x) {
            x.className = x.className.replace("show", "");
          }
        }, 3000);
      }
    },
  },
})
export default class Home extends Vue {}
</script>

<style>
#footer {
  background-color: #d2d2d2;
  border-top: 1px solid #e9e9eb;
  margin-top: 50px;
}
#footer .tt {
  text-align: center;
  font-size: 25px;
  color: red;
  font-weight: bold;
  padding: 10px 0;
}
#footer .footer {
  background-color: #ff0000;
  padding: 10px;
  margin-top: 10px;
  color: white;
  text-align: center;
}
#footer .how {
  width: 50%;
  margin: auto;
}
#nav {
  display: flex;
}
@media screen and (max-width: 768px) {
  #footer .how {
    width: 90%;
    margin: auto;
  }
  #nav {
    text-align: center;
    display: block;
  }
  #nav ul li {
    float: none;
  }
  #nav ul {
    padding-right: 0;
  }
  #logo {
    padding: 0;
    width: 100%;
  }
  #form {
    width: 100% !important;
  }
  #logo img,
  #logo span {
    margin: auto;
  }
  h2 {
    width: 90% !important;
    font-size: 18px !important;
    margin: auto !important;
  }
  .eur {
    display: block !important;
    margin: 20px auto !important;
  }
  .eur span {
    display: block;
    font-size: 16px;
  }
  h1 {
    font-size: 20px !important;
  }
  .radio input {
    width: fit-content !important;
    margin: 5px;
  }
  .radio div {
    width: fit-content !important;
    display: inline;
  }
}
.homecnt {
  margin-top: 40px;
  height: 100%;
  display: block;
  align-items: center;
}
#photo {
  margin: auto;
}
#title {
  text-align: center;
  font-size: 50px;
  padding: 40px;
  color: white;
  text-shadow: 0 0 4px black;
}
.formCnt{
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #0c0c0c5e;
  display: flex;
  align-items: center;
}
.homeX{
    position: absolute;
    top: 0;
    right: 0;
    color: red;
    font-weight: bold;
    font-size: 20px;
    padding: 5px 10px;
    text-align: center;
    border-top-right-radius: 10px;
    cursor: pointer;
}
.homeX:hover{
    color: #fafafc;
    background-color: red;
}
#form {
  position: relative;
  padding: 50px 40px;
  background-color: #fafafc;
  border: 1px solid #e9e9eb;
  margin-left: auto;
  margin-right: 50px;
  border-radius: 10px;
  width: 40%;
  min-width: 400px;
  position: relative;
  padding-bottom: 55px;
  padding-bottom: 0;
  margin: auto;
}
#form .btn {
  width: 100%;
  text-align: center;
}
#form .btn button {
  padding: 10px 40px;
  background-color: red;
  border: none;
  color: white;
  border-radius: 20px;
  font-weight: bold;
  font-size: 19px;
  margin: 15px auto;
  cursor: pointer;
}
#form .btn button:hover {
  background-color: #d84747;
}
.input {
  display: grid;
  grid-template-columns: 40% 60%;
  margin-top: 20px;
  padding-left: 5px;
}
.input [type="text"],
.input [type="email"],
.input [type="password"],
.input [type="date"] ,select {
  box-shadow: 0 0 1px 0;
}
.error {
  color: red;
  border-left: 5px solid red;
  padding-left: 5px;
}
.input select{
  width: 100%;
}
.input input ,.input select {
  font-size: 20px;
  border: none;
  border-radius: 10px;
  background-color: white;
  padding: 5px 3px;
}
.input span {
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
}
#intrs input:nth-child(1) {
  margin: 0 20px 0 5px;
}
label {
  color: red;
}
@media screen and (max-width: 1200px) {
  .home {
    height: fit-content;
    display: block;
    text-align: center;
    padding-bottom: 50px;
  }
  .homecnt {
    height: fit-content;
    display: block;
    text-align: center;
    padding-bottom: 50px;
  }
  #laptop {
    width: 100vw;
  }
  #title {
    font-size: 30px;
    margin: 20px;
  }
  #photo {
    margin: auto;
  }
  #form {
    margin: auto;
    margin-top: 20px;
    min-width: 0;
    width: 90%;
    padding: 30px 0;
    padding-bottom: 50px;
    position: relative;
  }
  .input {
    display: block;
    margin-top: 20px;
  }
  .input span {
    overflow-wrap: break-word;
    display: block;
    text-align: center;
  }
  #intrs {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: auto;
  }
  .input input {
    width: 80%;
  }
  #intrs input {
    width: fit-content;
  }
  h2 {
    width: 90% !important;
    font-size: 18px !important;
    margin: auto !important;
  }
  .eur {
    display: block !important;
    margin: 20px auto !important;
  }
  .eur span {
    display: block;
    font-size: 16px;
  }
  h1 {
    font-size: 20px !important;
  }
  .radio input {
    width: fit-content !important;
    margin: 5px;
  }
  .radio div {
    width: fit-content !important;
    display: inline;
  }
}
@media screen and (max-height: 500px) {
  .home {
    height: fit-content;
  }
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 30px;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
.home .alert {
  background-color: #ffffff9c !important;
  width: 100% !important;
  height: 100vh !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 2 !important;
  display: flex !important;
  align-items: center !important;
}
.home .alert .alr {
  width: 600px !important;
  height: 420px !important;
  background-color: #0e95d3 !important;
  background-image: url("../assets/popup.png") !important;
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin: auto !important;
  border-radius: 20px !important;
  position: relative !important;
  padding: 25px 0 !important;
}
.home .alert .alr .x {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 20px !important;
  padding: 5px 10px !important;
  text-align: center !important;
  border-top-right-radius: 20px !important;
  cursor: pointer !important;
}
.home .alert .alr .x:hover {
  background-color: #4edc54 !important;
}
.home .child {
  text-align: center !important;
  color: white !important;
  margin: 10px 0 20px 0 !important;
}
.home .alrTitle {
  font-weight: bold !important;
  font-size: 20px !important;
  width: 50% !important;
  margin-left: auto !important;
  margin-right: 30px !important;
  margin-top: 50px !important;
}
.load {
  position: fixed;
  height: 100vh;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff6e;
  top: 0;
}
.lds-ring {
  margin: auto;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid red;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: red transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
